<p>Er du sikker på du vil slette?</p>
<button mat-raised-button color="primary"
    (click)="delete()">
    <mat-icon>delete</mat-icon>
    Slet
</button>
<button mat-raised-button color="primary"
    (click)="cancel()">
    <mat-icon>cancel</mat-icon>
    Annuller
</button>