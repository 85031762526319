import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { catchError, Observable, take, throwError } from 'rxjs';
import { PersonDto, ReceiptCategoryDto, RuleDto } from 'src/app/api/models';
import { PersonService, ReceiptCategoryService, RuleService } from 'src/app/api/services';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';
import { DeleteConfirmation } from 'src/app/components/delete-confirmation/delete-confirmation.component';
import { UpsertRuleComponent } from './upsert-rule-component/upsert-rule.component';

@Component({
    selector: 'rules',
    templateUrl: 'rules.component.html',
    styleUrls: ['rules.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
  })
  export class RuleComponent {
    public columnsToDisplay: string[] = ['name', 'person', 'category', 'rules', 'delete', 'edit'];
    public rules = new MatTableDataSource<RuleDto>();
    public people: PersonDto[] = [];
    public categories: ReceiptCategoryDto[] = [];
    public selectedRule?: RuleDto;  
    public searchTerm = '';
    public toastMessage: ToastMessage = new ToastMessage();

    public constructor(
      public ruleService: RuleService,
      public personService: PersonService,
      public categoryService: ReceiptCategoryService,
      public dialog: MatDialog){
      this.rules = new MatTableDataSource<RuleDto>();
    }

    ngOnInit(){
      this.refresh();
    }
    
    refresh(){
      this.ruleService.ruleGetAll()
      .subscribe((data: RuleDto[]) => {
          this.rules.data = data;
      });

      this.personService.personGetAll()
      .subscribe((data: PersonDto[]) => {
        this.people = data;
      })

      this.categoryService.receiptCategoryGetAll()
      .subscribe((data: ReceiptCategoryDto[]) => {
        this.categories = data;
      })
    }

    getPerson(rule: RuleDto): string {
      return this.people.find(p => p.id === rule.personId)?.name ?? 'Ukendt'
    }

    getCategory(rule: RuleDto): string {
      return this.categories.find(c => c.id === rule.categoryId)?.name ?? 'Ukendt'
    }

    delete(period: RuleDto){
      this.dialog.open(DeleteConfirmation)
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.ruleService
            .ruleDeleteReceiptCategoryId({id: period.id!})
            .pipe(
              catchError((err: HttpErrorResponse): Observable<never> => {
                this.toastMessage = new ToastMessage("Noget gik galt, reglen blev ikke slettet!", ToastStatus.Error);
                return throwError(err);
              }),
              take(1)
            )
            .subscribe(() =>  
            {
              this.toastMessage = new ToastMessage("Reglen blev slettet!", ToastStatus.Success);
              this.rules.data = this.rules.data
                .filter((r: RuleDto) => r.id !== period.id);
            });
          }
        })
    }

    selectRule(rule: RuleDto){
      this.selectedRule = this.selectedRule === rule 
        ? undefined 
        : rule;
    }
    
    add(){
      this.dialog.open(UpsertRuleComponent)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.toastMessage = new ToastMessage("Reglen blev oprettet!");
        }
        this.refresh();
      });
    }

    edit(rule: RuleDto){
      let dialogref = this.dialog.open(UpsertRuleComponent, {
        maxWidth: 1200,
      })
      dialogref.afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.toastMessage = new ToastMessage("Reglen blev redigeret!");
          }
          this.refresh();
        });
        console.log(rule)
      dialogref.componentInstance.rule = rule;
    }
  }
