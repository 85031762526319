/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RuleDto } from '../models/rule-dto';

@Injectable({
  providedIn: 'root',
})
export class RuleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation ruleGetAll
   */
  static readonly RuleGetAllPath = '/v1/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruleGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruleGetAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RuleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RuleService.RuleGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RuleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ruleGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruleGetAll(params?: {
    context?: HttpContext
  }
): Observable<Array<RuleDto>> {

    return this.ruleGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RuleDto>>) => r.body as Array<RuleDto>)
    );
  }

  /**
   * Path part for operation ruleCreateUpdateRule
   */
  static readonly RuleCreateUpdateRulePath = '/v1/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruleCreateUpdateRule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ruleCreateUpdateRule$Response(params: {
    context?: HttpContext
    body: RuleDto
  }
): Observable<StrictHttpResponse<RuleDto>> {

    const rb = new RequestBuilder(this.rootUrl, RuleService.RuleCreateUpdateRulePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RuleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ruleCreateUpdateRule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ruleCreateUpdateRule(params: {
    context?: HttpContext
    body: RuleDto
  }
): Observable<RuleDto> {

    return this.ruleCreateUpdateRule$Response(params).pipe(
      map((r: StrictHttpResponse<RuleDto>) => r.body as RuleDto)
    );
  }

  /**
   * Path part for operation ruleSimulate
   */
  static readonly RuleSimulatePath = '/v1/rules/simulate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruleSimulate()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruleSimulate$Response(params?: {
    receiptItemName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RuleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RuleService.RuleSimulatePath, 'get');
    if (params) {
      rb.query('receiptItemName', params.receiptItemName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RuleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ruleSimulate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruleSimulate(params?: {
    receiptItemName?: string;
    context?: HttpContext
  }
): Observable<Array<RuleDto>> {

    return this.ruleSimulate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RuleDto>>) => r.body as Array<RuleDto>)
    );
  }

  /**
   * Path part for operation ruleDeleteReceiptCategoryId
   */
  static readonly RuleDeleteReceiptCategoryIdPath = '/v1/rules/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruleDeleteReceiptCategoryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruleDeleteReceiptCategoryId$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RuleService.RuleDeleteReceiptCategoryIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ruleDeleteReceiptCategoryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruleDeleteReceiptCategoryId(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.ruleDeleteReceiptCategoryId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
