<mat-dialog-content>
  <div class="receipt-header">
    <h3>{{receipt?.referenceId}}</h3>
    <h3>Total: {{totalSum}}</h3>
  </div>
  <form>
    <hr>
    <div class="receipt-header-div">
      <div class="receipt-info-div">
      <mat-form-field appearance="legacy">
        <mat-label>Betaler</mat-label>
        <mat-select [(ngModel)]="receiptPerson" name="person" matNativeControl required [formControl]="personFormControl">
          <mat-option [value]=null>Ingen</mat-option>
          <mat-option *ngFor="let person of people" [value]="person.id">
            {{person.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="personFormControl.hasError('required')">
          Dette felt er påkrævet
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Butik</mat-label>
        <input matInput [value]="store" [(ngModel)]="store" matInput type="string" matNativeControl [formControl]="storeFormControl">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Købs dato:</mat-label>
        <input
          matInput
          [ngModel]="purchaseDate"
          [matDatepicker]="dp"
          [formControl]="purchaseDateFormControl">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
        <mat-error *ngIf="personFormControl.hasError('required')">
          Dette felt er påkrævet
        </mat-error>
        </mat-form-field>
      </div>
      <div class="button-div">
        <button mat-raised-button color="primary" 
        [disabled]="personFormControl.hasError('required') || purchaseDateFormControl.hasError('required')" 
        (click)="update()">
          <mat-icon>save</mat-icon>
          Gem
        </button>
        <button mat-raised-button color="warn" 
          (click)="dialogRef?.close();">
          <mat-icon>cancel</mat-icon>
          Annuller
        </button>
      </div>
    </div>
    <hr>
  </form>  
    
    <form [formGroup]="receiptForm">
    <div formArrayName="receiptItems" *ngFor="let receiptItem of receiptItemArray.controls; let i = index;">
      <div [formGroupName]="i" class="receipt-item-div">
        <mat-form-field appearance="legacy">
          <mat-label>Navn</mat-label>
          <input matInput type="string" formControlName="name" placeholder="Navn">
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Pris</mat-label>
          <input matInput type="number" formControlName="amount" placeholder="Pris">
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Kategori</mat-label>
          <mat-select formControlName="categoryId" name="receiptCategory">
            <mat-option [value]=null>Ingen</mat-option>
            <mat-option *ngFor="let receiptCategory of receiptCategories" [value]="receiptCategory.id">
              {{receiptCategory.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Fælles</mat-label>
          <mat-select formControlName="personId" name="person">
            <mat-option [value]=null>Fælles</mat-option>
            <mat-option *ngFor="let person of people" [value]="person.id">
              {{person.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Kommentar</mat-label>
          <input matInput type="string" formControlName="comment" placeholder="Kommentar">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="removeRow(i)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </form>
  <button mat-raised-button color="primary" type="button" (click)="addRow()"><mat-icon>playlist_add</mat-icon>Tilføj vare</button>
</mat-dialog-content>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>