import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ReceiptCategoryDto, ReceiptDto } from 'src/app/api/models';
import { ReceiptCategoryService } from 'src/app/api/services';
import { MatDialog } from '@angular/material/dialog';
import { AddReceiptCategoryComponent } from './add-receipt-category-component/add-receipt-category.component';
import { DeleteConfirmation } from 'src/app/components/delete-confirmation/delete-confirmation.component';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError, take } from 'rxjs';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';

@Component({
  selector: 'receipt-category-list-view',
  styleUrls: ['receipt-category-list-view.component.scss'],
  templateUrl: 'receipt-category-list-view.component.html',
})
export class ReceiptCategoryComponent {
  displayedColumns = ['name', 'delete', 'edit'];
  receiptCategories = new MatTableDataSource<ReceiptCategoryDto>();
  toastMessage: ToastMessage = new ToastMessage();
  constructor(public receiptCategoryService: ReceiptCategoryService,
    public dialog: MatDialog){}

  ngOnInit(){
    this.refresh();
  }
  
  refresh(){
    this.receiptCategoryService.receiptCategoryGetAll()
      .subscribe((data: ReceiptCategoryDto[]) => {
        this.receiptCategories.data = data.sort((a,b) => (a.name ?? '') > (b.name ?? '') ? 1 : -1);
    });
  }

  delete(receipt: ReceiptDto){
    this.dialog.open(DeleteConfirmation)
    .afterClosed()
    .subscribe((result: boolean) => {
      if (result){
        this.receiptCategoryService
        .receiptCategoryDeleteReceiptCategoryId({id: receipt.id!})
        .pipe(
          catchError((err: HttpErrorResponse): Observable<never> => {
            this.toastMessage = new ToastMessage("Noget gik galt, kategorien blev ikke slettet!", ToastStatus.Error);
            return throwError(err);
          }),
          take(1)
        )
        .subscribe(() =>  
        {
          this.toastMessage = new ToastMessage("Kategorien blev successfuldt slettet!", ToastStatus.Success);
          this.receiptCategories.data = this.receiptCategories.data
            .filter((r: ReceiptCategoryDto) => r.id !== receipt.id);
        });
      }
    })
  }

  add(){
    this.dialog.open(AddReceiptCategoryComponent)
        .afterClosed()
        .subscribe((result: boolean) => {
            if (result) {
              this.toastMessage = new ToastMessage("Kategorien blev successfuldt oprettet!", ToastStatus.Success);
            }
            this.refresh();
        });;
    }
  
    edit(category: ReceiptCategoryDto){
      let dialogref = this.dialog.open(AddReceiptCategoryComponent, {
        maxWidth: 1200,
      })
      dialogref.afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.toastMessage = new ToastMessage("Kategorien blev successfuldt redigeret!");
          }
          this.refresh();
        });
      dialogref.componentInstance.receiptCategory = category;
    }
}