import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { catchError, Observable, take, throwError } from 'rxjs';
import { PersonSettlementPeriodDto, SettlementPeriodDto } from 'src/app/api/models';
import { SettlementPeriodService } from 'src/app/api/services';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';
import { AddSettlementPeriodComponent } from './add-settlement-period/add-settlement-period.component';
import { CreateSettlemementPeriodForm } from './create-settlement-period-form/create-settlement-period-form.component';
import { Router } from '@angular/router';
import { DeleteConfirmation } from 'src/app/components/delete-confirmation/delete-confirmation.component';

@Component({
    selector: 'settlements.component',
    templateUrl: 'settlements.component.html',
    styleUrls: ['settlements.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
  })
  export class SettlementComponent {
    public columnsToDisplay: string[] = ['startDate', 'endDate', 'status', 'amount', 'delete', 'edit', 'gotoReceipts'];
    public settlementPeriods = new MatTableDataSource<SettlementPeriodDto>();
    public personSettlementPeriods = new MatTableDataSource<PersonSettlementPeriodDto>();
    public selectedSettlementPeriod?: SettlementPeriodDto;
    public searchTerm = '';
    public toastMessage: ToastMessage = new ToastMessage();
    columnsToDisplayPeriodItems = ['name', 'totalAmount', 'totalPersonalAmount', 'personalNotPaidBySelfAmount', 'personalPaidBySelfAmount', 
    'totalSharedAmount', 'sharedNotPaidBySelfAmount', 'sharedPaidBySelfAmount', 'balance']
    columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
    public constructor(
      private router: Router,
      public settlementPeriodService: SettlementPeriodService,
      public dialog: MatDialog){
      this.settlementPeriods = new MatTableDataSource<SettlementPeriodDto>();
    }

    ngOnInit(){
      this.refresh();
    }
    
    refresh(){
      this.settlementPeriodService.settlementPeriodSearchSettlementPeriods()
      .subscribe((data: SettlementPeriodDto[]) => {
          this.settlementPeriods.data = data;
      });
    }

    gotoReceipts(settlementPeriod: SettlementPeriodDto){
      this.router.navigateByUrl('', {state: {startDate: settlementPeriod.startDate, endDate: settlementPeriod.endDate}} )
    }

    delete(period: SettlementPeriodDto){
      this.dialog.open(DeleteConfirmation)
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.settlementPeriodService
            .settlementPeriodDeleteSettlementPeriodId({id: period.id!})
            .pipe(
              catchError((err: HttpErrorResponse): Observable<never> => {
                this.toastMessage = new ToastMessage("Noget gik galt, afregningsperioden blev ikke slettet!", ToastStatus.Error);
                return throwError(err);
              }),
              take(1)
            )
            .subscribe(() =>  
            {
              this.toastMessage = new ToastMessage("Afregningsperioden blev slettet!", ToastStatus.Success);
              this.settlementPeriods.data = this.settlementPeriods.data
                .filter((r: SettlementPeriodDto) => r.id !== period.id);
            });
          }
        })
    }
  
    search(contains: string){
      this.settlementPeriodService
        .settlementPeriodSearchSettlementPeriods({contains: contains})
          .subscribe((data: SettlementPeriodDto[]) => {
            this.settlementPeriods.data = data;
    });
    }

    selectPeriod(period: SettlementPeriodDto){
      this.selectedSettlementPeriod = this.selectedSettlementPeriod === period 
        ? undefined 
        : period;
      this.personSettlementPeriods = this.selectPeriod === undefined
        ? new MatTableDataSource<PersonSettlementPeriodDto>()
        : this.personSettlementPeriods = new MatTableDataSource<PersonSettlementPeriodDto>(this.selectedSettlementPeriod?.personSettlementPeriods); 
    }

    add(){
      this.dialog.open(AddSettlementPeriodComponent)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.toastMessage = new ToastMessage("Afregningsperiode blev oprettet!");
        }
        this.refresh();
      });
    }

    edit(period: SettlementPeriodDto){
      let dialogref = this.dialog.open(CreateSettlemementPeriodForm, {
        maxWidth: 1200,
      })
      dialogref.afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.toastMessage = new ToastMessage("Afregningsperiode blev redigeret!");
          }
          this.refresh();
        });
      dialogref.componentInstance.settlementPeriod = period;
    }
  }
