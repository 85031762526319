import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { StatisticDto } from 'src/app/api/models/statistic-dto';
import { StatisticSeriesDto } from 'src/app/api/models/statistic-series-dto';
import { StatisticType } from 'src/app/api/models/statistic-type';
import { StatisticService } from 'src/app/api/services';

@Component({
  selector: 'statistic.component',
  templateUrl: 'statistic.component.html',
  styleUrls: ['statistic.component.scss']
})
export class StatisticComponent implements OnInit {
  public peopleStatistics: StatisticDto[] = [];
  public categoryStatistics: StatisticDto[] = [];
  public periodStatistics: StatisticDto[] = [];
  public peopleAndPeriodStatistics: StatisticSeriesDto[] = [];
  public peopleAndCategoriesStatistics: StatisticSeriesDto[] = [];
  public categoriesAndPeriodStatistics: StatisticSeriesDto[] = [];
  public statisticChoices = StatisticChoices;
  public choices: string[] = [];
  public choice: string = '';
  public activeEntries: any[] = [];
  range = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });
  constructor(
    public receiptService: StatisticService,
    public datePipe: DatePipe
  ){}

  ngOnInit(): void {
    this.choices = Object.values(StatisticChoices);
    this.refresh();
    this.range.valueChanges.subscribe(() => {
      this.refresh();
    });
  }

  refresh(){
    const fromDate = this.datePipe.transform(this.range.value.startDate, "yyyy/MM/dd");
    const toDate = this.datePipe.transform(this.range.value.endDate, "yyyy/MM/dd");

    this.receiptService
    .statisticGetStatistic({
      type: StatisticType.People,
      to: toDate ?? undefined,
      from: fromDate ?? undefined,
    })
    .subscribe((response: StatisticDto[]) => {
      this.peopleStatistics = response;
    });
  
    this.receiptService
    .statisticGetStatistic({
      type: StatisticType.ReceiptCategories,
      to: toDate ?? undefined,
      from: fromDate ?? undefined,
    })
    .subscribe((response: StatisticDto[]) => {
      this.categoryStatistics = response;
    });

    this.receiptService
    .statisticGetStatistic({
      type: StatisticType.SettlementPeriods,
      to: toDate ?? undefined,
      from: fromDate ?? undefined,
    })
    .subscribe((response: StatisticDto[]) => {
      this.periodStatistics = response;
    });

    this.receiptService
    .statisticGetStatisticSeries({
      firstType: StatisticType.People,
      secondType: StatisticType.ReceiptCategories,
      to: toDate ?? undefined,
      from: fromDate ?? undefined,
    })
    .subscribe((response: StatisticSeriesDto[]) => {
      this.peopleAndCategoriesStatistics = response;
    });

    this.receiptService
    .statisticGetStatisticSeries({
      firstType: StatisticType.SettlementPeriods,
      secondType: StatisticType.ReceiptCategories,
      to: toDate ?? undefined,
      from: fromDate ?? undefined,
    })
    .subscribe((response: StatisticSeriesDto[]) => {
      this.categoriesAndPeriodStatistics = response;
    });

    this.receiptService
    .statisticGetStatisticSeries({
      firstType: StatisticType.People,
      secondType: StatisticType.SettlementPeriods,
      to: toDate ?? undefined,
      from: fromDate ?? undefined,
    })
    .subscribe((response: StatisticSeriesDto[]) => {
      this.peopleAndPeriodStatistics = response;
    });
  }
  view: [number, number] = [700, 400];

  public gradient = false;
  public yAxisLabel = 'Beløb';

  onSelect(event: any) {
    console.log(event)
    console.log(this.activeEntries);
    this.activeEntries.push(event);
  }
}

export enum StatisticChoices {
  People = 'Personer',
  Categories = 'Kategorier',
  Periods = 'Perioder',
  PeopleAndCategories = 'Personer + Kategorier',
  PeriodsAndPeople = 'Perioder + Personer',
  PeriodsAndCategories = 'Perioder + Kategorier'
}