import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, Observable, throwError, take } from 'rxjs';
import { ReceiptCategoryDto } from 'src/app/api/models';
import { ReceiptCategoryService } from 'src/app/api/services';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';


@Component({
    selector: 'add-receipt-category.component',
    templateUrl: 'add-receipt-category.component.html',
    styleUrls: ['add-receipt-category.component.scss']
  })

export class AddReceiptCategoryComponent {
    constructor(
        public receiptCategoryService: ReceiptCategoryService,
        private dialogRef: MatDialogRef<AddReceiptCategoryComponent>) {}
    receiptCategory: ReceiptCategoryDto = {};
    selectFormControl = new FormControl('', Validators.required);
    submitted = false;
    toastMessage: ToastMessage = new ToastMessage();
    
    onSubmit() { 
        this.submitted = true;
        this.receiptCategoryService
            .receiptCategoryCreateUpdateReceiptCategory({body: this.receiptCategory})
            .pipe(
                catchError((err: HttpErrorResponse): Observable<never> => {
                  this.toastMessage = new ToastMessage("Noget gik galt, kategorien blev ikke oprettet!", ToastStatus.Error);
                  return throwError(err);
                }),
                take(1)
              )
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }
  }