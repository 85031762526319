<div class="wrap">
    <div class="left-wrapper">
        <div class="people-table">
            <person-list-view></person-list-view>
        </div>
        <div class="category-table">
            <receipt-category-list-view></receipt-category-list-view>
        </div>
    </div>

    <div class="rule-table">
        <rules></rules>
    </div>    
</div>
