import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import { ReceiptDto, ReceiptItemDto, SortDirection } from 'src/app/api/models';
import { PersonService, ReceiptService } from 'src/app/api/services';
import { AddReceiptComponent } from './add-receipt/add-receipt.component';
import { MatDialog } from '@angular/material/dialog';
import { ReceiptFormComponent } from './add-receipt-form/receipt-form.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { StrictHttpResponse } from 'src/app/api/strict-http-response';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';
import { catchError, Observable, take, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteConfirmation } from 'src/app/components/delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'receipts.component',
  styleUrls: ['receipts.component.scss'],
  templateUrl: 'receipts.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReceiptsComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;  
  pageSizes = [5, 10, 15, 20, 25, 50];
  totalData = 0;
  skip = 0;
  pageSize = 50;
  currentPage = 0;
  sortColumnKey: string = 'PurchaseDate';
  sortDirection: SortDirection = SortDirection.Asc;
  range = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });
  columnsToDisplay = ['referenceId', 'store', 'purchaseDate', 'amount', 'user', 'delete', 'edit'];
  columnsToDisplayReceiptItems = ['name', 'amount', 'settled', 'type']
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  receipts = new MatTableDataSource<ReceiptDto>();
  receiptItems = new MatTableDataSource<ReceiptItemDto>();
  selectedReceipt?: ReceiptDto;
  public toastMessage: ToastMessage = new ToastMessage();

  searchTerm = '';

  constructor(
    public receiptService: ReceiptService,
    public dialog: MatDialog,
    public personService: PersonService,
    public datePipe: DatePipe){}

  ngOnInit(){
    this.range.controls['startDate'].setValue(history.state.startDate);
    this.range.controls['endDate'].setValue(history.state.endDate);
    this.refresh();
  }
  
  ngAfterViewInit(): void {
    this.paginator.length = this.totalData;
    this.receipts.sort = this.sort; 
    this.sort.sortChange.subscribe(() => {
      this.sortColumnKey = this.sort.active !== '' ? this.sort.active : 'CreatedAt';
      switch (this.sort.direction){
        case "asc": {
          this.sortDirection = SortDirection.Asc;
          break;
        }
        case "desc": {
          this.sortDirection = SortDirection.Desc;
          break;
        }
        default: {
          this.sortDirection = SortDirection.Asc;
        }
      }
      this.search();
    });     
  }
  
  refresh(){
    this.search();
  }

  delete(receipt: ReceiptDto){
    this.dialog.open(DeleteConfirmation)
    .afterClosed()
    .subscribe((result: boolean) => {
      if (result) {
        this.receiptService
        .receiptDeleteReceiptById({id: receipt.id!})
        .pipe(
          catchError((err: HttpErrorResponse): Observable<never> => {
            this.toastMessage = new ToastMessage("Noget gik galt, bonnen blev ikke slettet!", ToastStatus.Error);
            return throwError(err);
          }),
          take(1)
        )
        .subscribe(() =>  
        {
          this.toastMessage = new ToastMessage("Bonnen blev succesfuldt slettet!", ToastStatus.Success);
          this.receipts.data = this.receipts.data
            .filter((r: ReceiptDto) => r.id !== receipt.id);
        });
      }
    })

  }

  add(){
    let res = this.dialog.open(AddReceiptComponent, {
      maxWidth: 1200,
    })
      .afterClosed()
      .subscribe((success: boolean) => {
        if (success) {
          this.toastMessage = new ToastMessage("Bon blev succesfuldt oprettet!", ToastStatus.Success);
        }
        this.refresh();
      });
  }

  edit(receipt: ReceiptDto){
    let dialogref = this.dialog.open(ReceiptFormComponent, {
      maxWidth: 1200,
      width: '1200px',
    })
    dialogref.afterClosed()
      .subscribe((success: boolean) => {
        if (success) {
          this.toastMessage = new ToastMessage("Bon blev succesfuldt opdateret!", ToastStatus.Success);
        }
        this.refresh();
        });
    dialogref.componentInstance.receipt = receipt;
  }

  selectReceipt(receipt: ReceiptDto){
    this.selectedReceipt = this.selectedReceipt === receipt 
    ? undefined 
    : receipt;
    this.receiptItems = this.selectedReceipt === undefined
      ? new MatTableDataSource<ReceiptItemDto>()
      : this.receiptItems = new MatTableDataSource<ReceiptItemDto>(this.selectedReceipt.receiptItems); 
  }

  handlePagination(event: PageEvent){
    this.pageSize = event.pageSize;
    this.skip = event.pageIndex  * this.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  search(contains?: string){
    const startDate = this.datePipe.transform(this.range.value.startDate, "yyyy/MM/dd");
    const endDate = this.datePipe.transform(this.range.value.endDate, "yyyy/MM/dd");
    this.receiptService
      .receiptSearchReceipts$Response({
        contains: contains,
        to: endDate ?? undefined,
        from: startDate ?? undefined,
        Limit: this.pageSize, 
        Skip: this.skip, 
        SortBy: this.sortColumnKey,
        SortOrder: this.sortDirection})
        .subscribe((response: StrictHttpResponse<ReceiptDto[]>): void => {
            this.receipts.data = response.body;
            this.totalData = Number(response.headers.get('X-Total-Count') ?? 0);
  });
  }

  getPerson(id: string): string{
    let personName = '';
    this.personService.personGetPersonById({id: id})
      .subscribe((person) => person.name);
    return personName;
  }
}

export interface ReceiptDtoExtended extends ReceiptDto{
  personName: string;
}