import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, take } from 'rxjs';
import { PersonDto } from 'src/app/api/models';
import { PersonService } from 'src/app/api/services';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';

@Component({
  selector: 'add-person.component',
  templateUrl: 'add-person.component.html',
  styleUrls: ['add-person.component.scss']
})
export class AddPersonComponent {
  constructor(
    public personService: PersonService,
    private dialogRef: MatDialogRef<AddPersonComponent>
  ) {}

  person: PersonDto = {};
  submitted = false;
  nameFormControl = new FormControl('', Validators.required);
  last4DigitsFormControl = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]); // Adding validation for last 4 digits
  toastMessage: ToastMessage = new ToastMessage();

  onSubmit() {
    this.submitted = true;
    if (this.nameFormControl.valid && this.last4DigitsFormControl.valid) {
      this.personService
        .personCreateUpdatePerson({ body: this.person })
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.toastMessage = new ToastMessage(
              'Noget gik galt, personen blev ikke oprettet!',
              ToastStatus.Error
            );
            return [];
          }),
          take(1)
        )
        .subscribe();
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
