<div class="settlement-periods-header-container">
  <div class="settlement-periods-header">
    <mat-form-field appearance="fill">
      <mat-label>Søg afregningsperioder</mat-label>
      <input
        matInput
        [(ngModel)]="searchTerm"
        (input)="search(searchTerm)"
        placeholder="Søg afregningsperioder"
      />
      <mat-icon class='search-icon' color="primary" matPrefix (click)="search(searchTerm)"
        >search</mat-icon
      >
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="add()">
      <mat-icon>note_add</mat-icon> 
      Tilføj afregningsperiode
    </button>
  </div>
</div>

<table mat-table
       [dataSource]="settlementPeriods" multiTemplateDataRows
       class="settlement-periods-table">
       <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>Start dato</th>
        <td mat-cell *matCellDef="let element">{{element.startDate}}</td>
      </ng-container>
    
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>Slut dato</th>
        <td mat-cell *matCellDef="let element">{{element.endDate}}</td>
      </ng-container>
    
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
      </ng-container>
    
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Beløb</th>
        <td mat-cell *matCellDef="let element">{{element.amount}}</td>
      </ng-container>
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>Slet</th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" 
        aria-label="Example icon button with a delete icon"
        (click)="delete(element)">
          <mat-icon>delete</mat-icon>
        </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>Rediger</th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" aria-label="" (click)="edit(element)">
        <mat-icon>create</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="gotoReceipts">
    <th mat-header-cell *matHeaderCellDef>Se bonner</th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" aria-label="" (click)="gotoReceipts(element)">
        <mat-icon>file_copy</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="selectPeriod(element); $event.stopPropagation()">
        <mat-icon *ngIf="selectedSettlementPeriod !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="selectedSettlementPeriod === element">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>  

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail"
           [@detailExpand]="element == selectedSettlementPeriod ? 'expanded' : 'collapsed'">
           <table mat-table [dataSource]="personSettlementPeriods" class="settlement-period-items-table">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Navn</th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="totalAmount">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let element">{{element.totalAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="personalNotPaidBySelfAmount">
              <th mat-header-cell *matHeaderCellDef>Personlig ikke betalt</th>
              <td mat-cell *matCellDef="let element">{{element.personalNotPaidBySelfAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="personalPaidBySelfAmount">
              <th mat-header-cell *matHeaderCellDef>Personlig betalt beløb</th>
              <td mat-cell *matCellDef="let element">{{element.personalPaidBySelfAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="totalSharedAmount">
              <th mat-header-cell *matHeaderCellDef>Total fælles</th>
              <td mat-cell *matCellDef="let element">{{element.totalSharedAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="sharedNotPaidBySelfAmount">
              <th mat-header-cell *matHeaderCellDef>Fælles ikke betalt</th>
              <td mat-cell *matCellDef="let element">{{element.sharedNotPaidBySelfAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="sharedPaidBySelfAmount">
              <th mat-header-cell *matHeaderCellDef>Fælles betalt</th>
              <td mat-cell *matCellDef="let element">{{element.sharedPaidBySelfAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="totalPersonalAmount">
              <th mat-header-cell *matHeaderCellDef>Total personlig</th>
              <td mat-cell *matCellDef="let element">{{element.totalPersonalAmount}}</td>
            </ng-container>
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef>Balance</th>
              <td mat-cell *matCellDef="let element">{{element.balance}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayPeriodItems"></tr>
            <tr
                mat-row *matRowDef="let row; columns: columnsToDisplayPeriodItems;"
            ></tr>
          </table>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="example-element-row"
      [class.example-expanded-row]="selectedSettlementPeriod === element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>