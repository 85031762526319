<div class="receipts-header-container">
  <div class="receipts-header">
    <mat-form-field appearance="fill">
      <mat-label>Søg bonner</mat-label>
      <input
        matInput
        [(ngModel)]="searchTerm"
        (input)="search(searchTerm)"
        placeholder="Søg bonner"
      />
      <mat-icon class='search-icon' color="primary" matPrefix (click)="search(searchTerm)"
        >search</mat-icon
      >
    </mat-form-field>
    
    <mat-form-field appearance="fill" >
      <mat-label>Indsæt dato interval</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="startDate" placeholder="Start dato">
        <input matEndDate formControlName="endDate" placeholder="Slut dato">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-paginator
      [length]="totalData" 
      [pageIndex]="currentPage" 
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizes"
      (page)="handlePagination($event)">
    </mat-paginator>
    
    <button class="search-button" mat-raised-button color="primary" (click)="search()">
      <mat-icon>search</mat-icon> 
      Søg
    </button>
    
    <button class="add-button" mat-raised-button color="primary" (click)="add()">
      <mat-icon>note_add</mat-icon> 
      Tilføj bon
    </button>
  </div>
  
</div>

<table mat-table
       [dataSource]="receipts" multiTemplateDataRows
       class="receipts-table"
       matSort>
  <ng-container matColumnDef="referenceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference ID</th>
      <td mat-cell *matCellDef="let element">{{element.referenceId}}</td>
  </ng-container>
  <ng-container matColumnDef="store">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Butik</th>
    <td mat-cell *matCellDef="let element">{{element.store}}</td>
  </ng-container>
  <ng-container matColumnDef="purchaseDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dato</th>
    <td mat-cell *matCellDef="let element">{{element.purchaseDate | date:'dd/MM/yyyy'}}</td>
  </ng-container>
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Beløb</th>
    <td mat-cell *matCellDef="let element">{{element.amount}}</td>
  </ng-container>
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>Betaler</th>
    <td mat-cell *matCellDef="let element">{{element.person ? element.person.name : ''}}</td>
  </ng-container>
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>Slet</th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" 
        aria-label="Slet"
        (click)="delete(element)">
          <mat-icon>delete</mat-icon>
        </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>Rediger</th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" aria-label="Rediger" (click)="edit(element)">
          <mat-icon>create</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="selectReceipt(element); $event.stopPropagation()">
        <mat-icon *ngIf="selectedReceipt !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="selectedReceipt === element">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>  

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="detailed-receipt-table"
           [@detailExpand]="element == selectedReceipt ? 'expanded' : 'collapsed'">
           <table mat-table [dataSource]="receiptItems" class="receipt-items-table">
            <!-- Position Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Vare</th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Pris</th>
              <td mat-cell *matCellDef="let element">{{element.amount}}</td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">{{element.category ? element.category.name : ''}}</td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="settled">
              <th mat-header-cell *matHeaderCellDef>Ejer</th>
              <td mat-cell *matCellDef="let element">{{element.person ? element.person.name : ''}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayReceiptItems"></tr>
            <tr
                mat-row *matRowDef="let row; columns: columnsToDisplayReceiptItems;"
            ></tr>
          </table>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="receipt-row"
      [class.receipt-row]="selectedReceipt === element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="receipt-detail-row"></tr>
</table>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>