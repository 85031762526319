import {
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import {ToastMessage} from './toast-message.class';

@Component({
  selector: 'lib-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})

export class ToastMessageComponent implements OnChanges {
  private isFirstInitialization: boolean = true;
  private readonly TIMEOUT_DURATION: number = 3000;

  @Input()
  public toastMessage!: ToastMessage;

  public displayToastMessage: ToastMessage = new ToastMessage();

  public ngOnChanges(): void {
    if (this.isFirstInitialization) {
      this.isFirstInitialization = false;

      return;
    }
    this.launchToast(this.toastMessage);
  }

  private launchToast(toastMessage: ToastMessage): void {
    if (this.displayToastMessage.text) {
      /*
       *wait for the displayToastMessage.text reset to empty for a set of time
       *then call the function launchToast again.
       */
      setTimeout((): void => {
        this.launchToast(toastMessage);
      }, this.TIMEOUT_DURATION);
    } else {
      this.displayToastMessage = toastMessage;
      setTimeout((): void => {
        this.displayToastMessage.reset();
      }, this.TIMEOUT_DURATION);
    }
  }
}