import {Component} from '@angular/core';

@Component({
    selector: 'add-settlement-period.component',
    templateUrl: 'add-settlement-period.component.html',
    styleUrls: ['add-settlement-period.component.scss']
  })
  
  export class AddSettlementPeriodComponent{

  }