<div class="statistic-header-div">
  <mat-form-field appearance="fill" >
    <mat-label>Indsæt dato interval</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="startDate" placeholder="Start dato">
      <input matEndDate formControlName="endDate" placeholder="Slut dato">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <!-- <mat-form-field appearance="fill">
    <mat-label>Favorite food</mat-label>
    <mat-select [(ngModel)]="choice">
      <mat-option *ngFor="let choice of choices" [value]="statisticChoices[choice]">
        {{choice}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div> -->

<div class="statistic-body-div">
    <ngx-charts-bar-vertical
    [view]="view"
    [results]="peopleStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Personer"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
    [activeEntries]="activeEntries">
  </ngx-charts-bar-vertical>
  <ngx-charts-bar-vertical
    [view]="view"
    [results]="categoryStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Kategorier"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical>
  <ngx-charts-bar-vertical
    [view]="view"
    [results]="periodStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Perioder"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical>
  <ngx-charts-bar-vertical-2d
    [view]="view"
    [results]="peopleAndPeriodStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Perioder"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical-2d>
  <ngx-charts-bar-vertical-2d
    [view]="view"
    [results]="peopleAndPeriodStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Perioder"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical-2d>
  <ngx-charts-bar-vertical-2d
    [view]="view"
    [results]="peopleAndCategoriesStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Personer"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical-2d>
  <ngx-charts-bar-vertical-2d
    [view]="view"
    [results]="categoriesAndPeriodStatistics"
    [gradient]="gradient"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Perioder"
    [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical-2d>
  
</div>

