<div class="container">
  <h3>Tilføj kategori</h3>
  <div [hidden]="submitted">
    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
      <div class="form-group">
        <mat-form-field appearance="legacy">
          <mat-label>Navn</mat-label>
          <input matInput type="string"class="form-control" id="name"
            required
            [(ngModel)]="receiptCategory.name" name="name"
            [formControl]="selectFormControl">
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary"
        type="submit" 
        [disabled]="selectFormControl.hasError('required')">
        <mat-icon>save</mat-icon>
        Gem
      </button>
    </form>
  </div>
</div>
<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>