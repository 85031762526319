import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/utils/app.guard';
import { SettlementComponent } from './pages/settlements/settlements.component';
import { SettingComponent } from './pages/settings/settings.component';
import { ReceiptsComponent } from './pages/receipts/receipts.component';
import { StatisticComponent } from './pages/statistics/statistic.component';

const routes: Routes = [
    { path: '', component: ReceiptsComponent, canActivate: [AuthGuard], data: {startDate: undefined, endDate: undefined}},
    { path: 'settings', component: SettingComponent, canActivate: [AuthGuard]},
    { path: 'settlements', component: SettlementComponent, canActivate: [AuthGuard]},
    { path: 'statistics', component: StatisticComponent, canActivate: [AuthGuard]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }