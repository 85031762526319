<ng-container *ngIf="displayToastMessage.text">
  <div class="toast" [ngClass]="displayToastMessage.status">
    <div class="img" [ngClass]="displayToastMessage.status">
      <mat-icon>check_circle</mat-icon>
    </div>
    <div class="desc">
      <span>{{ displayToastMessage.text }}</span>
    </div>
  </div>
</ng-container>
