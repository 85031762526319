import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog' 
import { MatInputModule } from '@angular/material/input' 
import { AppComponent } from './app.component';
import { ReceiptsComponent } from './pages/receipts/receipts.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddReceiptComponent } from './pages/receipts/add-receipt/add-receipt.component';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { SettingComponent } from './pages/settings/settings.component';
import { SettlementComponent } from './pages/settlements/settlements.component';
import { AddSettlementPeriodComponent } from './pages/settlements/add-settlement-period/add-settlement-period.component';
import { CreateSettlemementPeriodForm } from './pages/settlements/create-settlement-period-form/create-settlement-period-form.component';
import { ReceiptFormComponent } from './pages/receipts/add-receipt-form/receipt-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PersonComponent } from './pages/settings/person-component/person-list-view.component';
import { ReceiptCategoryComponent } from './pages/settings/receipt-categories-component/receipt-category-list-view.component';
import { AddPersonComponent } from './pages/settings/person-component/add-person-component/add-person.component';
import { AddReceiptCategoryComponent } from './pages/settings/receipt-categories-component/add-receipt-category-component/add-receipt-category.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from 'src/utils/app-init';
import { AppRoutingModule } from './app-routing.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { DeleteConfirmation } from './components/delete-confirmation/delete-confirmation.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StatisticComponent } from './pages/statistics/statistic.component';
import { RuleComponent } from './pages/settings/rules-component/rules.component';
import { UpsertRuleComponent } from './pages/settings/rules-component/upsert-rule-component/upsert-rule.component';
import { environment } from 'src/environments/environment';

registerLocaleData(localeDa);
const routes: Routes = [
  { path: '', component: ReceiptsComponent },
  { path: 'settings', component: SettingComponent},
  { path: 'settlements', component: SettlementComponent},
];
const MY_DATE_FORMATS = {
  parse: {
        dateInput: 'DD-MM-YYYY',

  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


@NgModule({
  declarations: [
    AppComponent,
    ReceiptsComponent,
    AddReceiptComponent,
    SettingComponent,
    SettlementComponent,
    AddSettlementPeriodComponent,
    CreateSettlemementPeriodForm,
    ReceiptFormComponent,
    PersonComponent,
    ReceiptCategoryComponent,
    AddPersonComponent,
    AddReceiptCategoryComponent,
    ToastMessageComponent,
    DeleteConfirmation,
    StatisticComponent,
    RuleComponent,
    UpsertRuleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    RouterModule.forRoot(routes),
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    KeycloakAngularModule,
    AppRoutingModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MomentDateModule,
    NgxChartsModule
    ],
    providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true,
        deps: [KeycloakService]
      },
      { provide: LOCALE_ID, useValue: 'da'},
      { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
      DatePipe,
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}

