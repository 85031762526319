<header>
  <div class="title">
    <h3><strong>Receipter</strong></h3>
    <p><em>Værktøj til håndtering af bonner.</em></p> 
  </div>


  <div class="menu">
    <button mat-raised-button color="primary" 
    (click)="logout()">
      <mat-icon>login</mat-icon>
      Log ud
    </button>
    <button mat-raised-button color="primary" (click)="gotoStatistics()">
      <mat-icon>bar_chart</mat-icon> 
      Statistik
    </button>
    <button mat-raised-button color="primary" (click)="gotoSettlements()">
      <mat-icon>note_add</mat-icon> 
      Afregninger
    </button>
    <button mat-raised-button color="primary" (click)="gotoReceipts()">
      <mat-icon>receipt</mat-icon> 
      Bonner
    </button>
    
    <button mat-raised-button color="primary" 
    (click)="gotoSettings()">
      <mat-icon>settings</mat-icon>
      Opsætning
    </button>
  </div>

</header>

<main>
  <router-outlet></router-outlet>
</main>

