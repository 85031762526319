<div class="container">
  <div class="header-container">
      <h3>Regler</h3>
      <button mat-raised-button color="primary" (click)="add()">
          <mat-icon>category</mat-icon>
          Tilføj
      </button>
  </div>
  
  <table mat-table
         [dataSource]="rules" multiTemplateDataRows
         class="rules-table">
         <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Navn</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
      
        <ng-container matColumnDef="person">
          <th mat-header-cell *matHeaderCellDef>Person</th>
          <td mat-cell *matCellDef="let element">{{getPerson(element)}}</td>
        </ng-container>
      
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Kategori</th>
          <td mat-cell *matCellDef="let element">{{getCategory(element)}}</td>
        </ng-container>
      
        <ng-container matColumnDef="rules">
          <th mat-header-cell *matHeaderCellDef>Regler</th>
          <td mat-cell *matCellDef="let element">{{element.rules}}</td>
        </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Slet</th>
      <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" 
          aria-label="Example icon button with a delete icon"
          (click)="delete(element)">
            <mat-icon>delete</mat-icon>
          </button>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Rediger</th>
      <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" aria-label="" (click)="edit(element)">
          <mat-icon>create</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>