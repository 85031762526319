<form [formGroup]="createSettlementPeriodForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="legacy">
    <mat-label>Status</mat-label>
    <mat-select formControlName="status">
      <mat-option *ngFor="let status of statusKeys" [value]="status">
        {{ status }}
      </mat-option>
    </mat-select>
  </mat-form-field>  

  <p></p>

  <mat-form-field appearance="legacy">
    <mat-label>Start dato:</mat-label>
    <input
      matInput
      [matDatepicker]="startDatePicker"
      formControlName="startDate">
    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>

  <p></p>

  <mat-form-field appearance="legacy">
    <mat-label>Slut dato:</mat-label>
    <input
      matInput
      [matDatepicker]="endDatePicker"
      formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>

  <p></p>

  <button mat-raised-button color="primary" 
    type="submit"
    [disabled]="!createSettlementPeriodForm.valid">Opret
  </button>
</form>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>