<div class="container">
  <h3>Tilføj regel</h3>
  <div [hidden]="submitted">
    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
      <div class="form-group">
        <mat-form-field appearance="legacy">
          <mat-label>Navn</mat-label>
          <input matInput type="string" id="name"
            required
            [(ngModel)]="rule.name" name="name"
            [formControl]="selectFormControl">
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Kategori</mat-label>
          <mat-select id="name"
            required
            [(ngModel)]="rule.categoryId" name="category">
            <mat-option [value]=null>Ingen</mat-option>
            <mat-option *ngFor="let receiptCategory of categories" [value]="receiptCategory.id">
              {{receiptCategory.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Fælles</mat-label>
          <mat-select id="name"
              [(ngModel)]="rule.personId" name="person">
            <mat-option [value]=null>Fælles</mat-option>
            <mat-option *ngFor="let person of people" [value]="person.id">
              {{person.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Regler</mat-label>
          <input matInput type="string" id="name" required [(ngModel)]="rules" name="rules">
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary"
        type="submit" 
        [disabled]="selectFormControl.hasError('required')">
        <mat-icon>save</mat-icon>
        Gem
      </button>
    </form>
  </div>
</div>
<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>