<input type="file" class="file-input"
       (change)="uploadFile($event)" #fileUpload>

<div class="file-upload" *ngIf="!receipt && !loading">
    <button mat-raised-button color="primary" class="upload-btn"
      (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
        Upload bon.
    </button>
    <p></p>
    <button mat-raised-button color="primary"
    (click)="createManually()">
    <mat-icon>create</mat-icon>
    Indtast bon.
  </button>
</div>


<mat-spinner *ngIf="loading"></mat-spinner>

<receipt-form *ngIf="receipt" [receipt]="receipt"></receipt-form>
