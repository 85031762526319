import {Component} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { PersonDto, ReceiptDto, ReceiptItemDto } from 'src/app/api/models';
import { PersonService, ReceiptService } from 'src/app/api/services';
import { MatDialog } from '@angular/material/dialog';
import { AddPersonComponent } from './add-person-component/add-person.component';
import { FormControl, Validators } from '@angular/forms';
import { DeleteConfirmation } from 'src/app/components/delete-confirmation/delete-confirmation.component';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError, take } from 'rxjs';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';

@Component({
  selector: 'person-list-view',
  styleUrls: ['person-list-view.component.scss'],
  templateUrl: 'person-list-view.component.html',
})
export class PersonComponent {
  displayedColumns = ['name', 'last4Digits', 'delete', 'edit'];
  persons = new MatTableDataSource<PersonDto>();
  toastMessage: ToastMessage = new ToastMessage();
  constructor(public personService: PersonService,
    public dialog: MatDialog){}

  ngOnInit(){
    this.refresh();
  }
  
  refresh(){
    this.personService.personGetAll().subscribe((data: PersonDto[]) => {
        this.persons.data = data;
    });
  }

  delete(receipt: ReceiptDto){
    this.dialog.open(DeleteConfirmation)
    .afterClosed()
    .subscribe((result: boolean) => {
      if (result) {
        this.personService
        .personDeletePersonById({id: receipt.id!})
        .pipe(
          catchError((err: HttpErrorResponse): Observable<never> => {
            this.toastMessage = new ToastMessage("Noget gik galt, personen blev ikke slettet!", ToastStatus.Error);
            return throwError(err);
          }),
          take(1)
        )
        .subscribe(() =>  
        {
          this.toastMessage = new ToastMessage("Personen blev successfuldt slettet!", ToastStatus.Success);
          this.persons.data = this.persons.data
            .filter((r: PersonDto) => r.id !== receipt.id);
        });
      }
    })
  }

  add(){
    this.dialog.open(AddPersonComponent)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.toastMessage = new ToastMessage("Personen blev successfuldt oprettet!", ToastStatus.Success);
        }
        this.refresh();
      });
  }

  edit(person: PersonDto){
    let dialogref = this.dialog.open(AddPersonComponent, {
      maxWidth: 1200,
    })
    dialogref.afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.toastMessage = new ToastMessage("Personen blev redigeret!");
        }
        this.refresh();
      });

    dialogref.componentInstance.person = person;
  }
}