import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'delete-confirmation',
    templateUrl: 'delete-confirmation.component.html',
    styleUrls: ['delete-confirmation.component.scss']
  })

  export class DeleteConfirmation {
    public constructor(public dialogRef: MatDialogRef<any>){}
    public delete(){
        this.dialogRef.close(true);
    }
    public cancel(){
        this.dialogRef.close(false);
    }
  }