import {ToastStatus} from './toast-status.enum';

export class ToastMessage {
  public text: string;
  public status: ToastStatus;

  public constructor(text: string = '', status: ToastStatus = ToastStatus.Default) {
    this.text = text;
    this.status = status;
  }

  public reset(): void {
    this.text = '';
    this.status = ToastStatus.Default;
  }
}