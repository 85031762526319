import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SettlementPeriodService } from 'src/app/api/services';
import { SettlementPeriodDto, SettlementPeriodStatus } from 'src/app/api/models';
import { MatDialogRef } from '@angular/material/dialog';
import { SettlementComponent } from '../settlements.component';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError, take } from 'rxjs';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';

@Component({
  selector: 'create-settlement-period-form',
  templateUrl: 'create-settlement-period-form.component.html',
  styleUrls: ['create-settlement-period-form.component.scss']
})
export class CreateSettlemementPeriodForm implements OnInit {
  @Input() public settlementPeriod?: SettlementPeriodDto;
  public toastMessage: ToastMessage = new ToastMessage();
  public statusKeys = Object.values(SettlementPeriodStatus);
  public periodStatus: SettlementPeriodStatus = this.settlementPeriod?.status ?? SettlementPeriodStatus.Open;
  public createSettlementPeriodForm: FormGroup = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  });

  constructor(
    public settlementPeriodService: SettlementPeriodService,
    public dialogRef: MatDialogRef<SettlementComponent>,
    public datePipe: DatePipe,
    public formBuilder: FormBuilder){
  }

  ngOnInit(): void {
    this.createSettlementPeriodForm = this.formBuilder.group({
      status: [this.settlementPeriod?.status || '', Validators.required],
      startDate: [this.settlementPeriod?.startDate || '', Validators.required],
      endDate: [this.settlementPeriod?.endDate || '', Validators.required]
    });
  }
  
  onSubmit() {
    let startDate = this.datePipe.transform(this.createSettlementPeriodForm.value.startDate, "dd/MM/yyyy")!;
    let endDate = this.datePipe.transform(this.createSettlementPeriodForm.value.endDate, "dd/MM/yyyy")!;
    if (!this.settlementPeriod) {
      this.settlementPeriod = {
        startDate: startDate,
        endDate: endDate,
        status: this.createSettlementPeriodForm.value.status,
      };
    }
    else {
      this.settlementPeriod.startDate = startDate;
      this.settlementPeriod.endDate = endDate;
      this.settlementPeriod.status = this.createSettlementPeriodForm.value.status;
    }

    this.settlementPeriodService
      .settlementPeriodCreateUpdateSettlementPeriod({body: this.settlementPeriod})
      .pipe(
        catchError((err: HttpErrorResponse): Observable<never> => {
          if (err.status == 409) {
            this.toastMessage = new ToastMessage("Noget gik galt, afregningsperioden overlapper med en anden!", ToastStatus.Warning);
          } else {
            this.toastMessage = new ToastMessage("Noget gik galt, afregningsperioden blev ikke slettet!", ToastStatus.Error);
          }
          
          return throwError(err);
        }),
        take(1)
      )
      .subscribe(() => {
        this.dialogRef?.close(true);
      });

  }

  dateSplitter(dateString: string): Date{
    var dateParts = dateString.split("/");
    return new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
  }
}