import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, Observable, throwError, take } from 'rxjs';
import { PersonDto, ReceiptCategoryDto, RuleDto } from 'src/app/api/models';
import { PersonService, ReceiptCategoryService, RuleService } from 'src/app/api/services';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';


@Component({
    selector: 'upsert-rule.component',
    templateUrl: 'upsert-rule.component.html',
    styleUrls: ['upsert-rule.component.scss']
  })

export class UpsertRuleComponent implements OnInit{
    constructor(
        public ruleService: RuleService,
        public receiptCategoryService: ReceiptCategoryService,
        public personService: PersonService,
        private dialogRef: MatDialogRef<UpsertRuleComponent>) {
        }
    
    ngOnInit(): void {
        this.receiptCategoryService.receiptCategoryGetAll()
        .subscribe((receiptCategories) => {
          this.categories = receiptCategories
            .sort((rc1, rc2) => (rc1.name?.toUpperCase() ?? '') > (rc2.name?.toUpperCase() ?? '') ? 1 : -1);
        });
        this.personService.personGetAll().subscribe((person) => this.people = person);

        if (this.rule.rules) {
            this.rules = this.convertListToString(this.rule.rules);
        }
    }

    convertListToString(rules: string[]): string {
        return rules.toString();
    }

    convertStringToList(rules?: string): string[] {
        return rules?.split(',') ?? [];
    }

    rule: RuleDto = {};
    rules: string = '';
    people: PersonDto[] = [];
    categories: ReceiptCategoryDto[] = [];
    submitted = false;
    selectFormControl = new FormControl('', Validators.required);
    toastMessage: ToastMessage = new ToastMessage();
    onSubmit() { 
        this.submitted = true;
        this.rule.rules = this.convertStringToList(this.rules);
        this.ruleService
            .ruleCreateUpdateRule({body: this.rule})
            .pipe(
                catchError((err: HttpErrorResponse): Observable<never> => {
                this.toastMessage = new ToastMessage("Noget gik galt, reglen blev ikke oprettet!", ToastStatus.Error);
                return throwError(err);
                }),
                take(1)
            )
            .subscribe();
        this.closeDialog();
    }

    closeDialog(){
        this.dialogRef.close(true);
      }
  }