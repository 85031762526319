<div class="container">
  <h3>Tilføj person</h3>
  <div [hidden]="submitted">
    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
      <div class="form-group">
        <mat-form-field appearance="legacy">
          <mat-label>Navn</mat-label>
          <input
            matInput
            type="string"
            class="form-control"
            id="name"
            required
            [(ngModel)]="person.name"
            name="name"
            [formControl]="nameFormControl"
          />
        </mat-form-field>

        <mat-form-field appearance="legacy">
          <mat-label>4 numre</mat-label>
          <input
            matInput
            type="string"
            class="form-control"
            id="digits"
            [(ngModel)]="person.last4Digits"
            name="digits"
            [formControl]="last4DigitsFormControl"
          />
        </mat-form-field>
      </div>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="nameFormControl.invalid || last4DigitsFormControl.invalid"
      >
        <mat-icon>save</mat-icon>
        Gem
      </button>
    </form>
  </div>
</div>
<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>
