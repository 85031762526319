import { Component, Input, OnInit, HostListener, OnChanges} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, Observable, take, throwError } from 'rxjs';
import { PersonDto, ReceiptCategoryDto, ReceiptDto, ReceiptItemDto } from "src/app/api/models";
import { PersonService, ReceiptCategoryService, ReceiptService } from 'src/app/api/services';
import { AddReceiptComponent } from '../add-receipt/add-receipt.component';
import { DatePipe } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http';
import { ToastMessage } from 'src/app/components/toast-message/toast-message.class';
import { ToastStatus } from 'src/app/components/toast-message/toast-status.enum';

@Component({
    selector: 'receipt-form',
    templateUrl: 'receipt-form.component.html',
    styleUrls: ['receipt-form.component.scss'],
  })

export class ReceiptFormComponent implements OnInit {
  @Input() public receipt?: ReceiptDto;
  @HostListener('keydown.control.a', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    this.update();
  }
  data?: ReceiptItemDto[] = this.receipt?.receiptItems;
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  receiptItemArray: FormArray = this.fb.array([]);
  receiptForm: FormGroup = this.fb.group({ 'receiptItems': this.receiptItemArray });
  receiptCategories: ReceiptCategoryDto[] = [];
  personFormControl = new FormControl('', Validators.required);
  storeFormControl = new FormControl('');
  people: PersonDto[] = [];
  receiptPerson: string = '';
  store: string = '';
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  purchaseDate: Date = new Date();
  purchaseDateFormControl = new FormControl('', Validators.required);
  toastMessage: ToastMessage = new ToastMessage();
  totalSum: number = 0;
  constructor(
    private fb: FormBuilder,
    public receiptService: ReceiptService,
    public receiptCategoryService: ReceiptCategoryService,
    public personService: PersonService,
    public dialogRef: MatDialogRef<AddReceiptComponent>,
    public datePipe: DatePipe) { }

  ngOnInit() {
    this.receipt?.receiptItems.forEach((d: ReceiptItemDto) => this.addRow(d, false));
    this.receiptCategoryService.receiptCategoryGetAll()
    .subscribe((receiptCategories) => {
      this.receiptCategories = receiptCategories
        .sort((rc1, rc2) => (rc1.name?.toUpperCase() ?? '') > (rc2.name?.toUpperCase() ?? '') ? 1 : -1);
    });
    this.personService.personGetAll().subscribe((person) => this.people = person);
    this.store = this.receipt ? this.receipt?.store : '';
    this.purchaseDate = new Date(this.receipt!.purchaseDate!);
    this.purchaseDateFormControl.setValue(this.dateSplitter(this.receipt!.purchaseDate!));
    if (this.receipt?.personId){
      this.receiptPerson = this.receipt?.personId;
    }
    this.updateView();
    this.calculateSum();
    this.receiptItemArray.valueChanges.subscribe(() => {
      this.calculateSum();
    });
  }

  addRow(d?: ReceiptItemDto, noUpdate?: boolean) {
    const row = this.fb.group({
      'name' : [d && d.name ? d.name : null, []],
      'amount' : [d && d.amount ? d.amount : null, []],
      'categoryId' :  [d && d.categoryId ? d.categoryId : null, []],
      'personId' :  [d && d.personId && d.personId !== '' ? d.personId : null, []],
      'receiptId' :  [d && d.receiptId && d.receiptId !== '' ? d.receiptId : this.emptyGuid, []],
      'comment' : [d && d.comment ? d.comment : '', []]
    });
    this.receiptItemArray.push(row);
    if (!noUpdate) { this.updateView(); }
  }

  removeRow(index: number) {
    this.receiptItemArray.removeAt(index);
    this.updateView();
  }

  updateView() {
    this.dataSource.next(this.receiptItemArray.controls);
  }
  
  calculateSum(){
    let sum = 0;
    for (let index = 0; index < this.receiptItemArray.length; index++) {
      sum += this.receiptItemArray.at(index).get('amount')?.value ?? 0;
    }

    this.totalSum = sum;
  }

  update(){
    let receiptItems = this.receiptItemArray.getRawValue() as ReceiptItemDto[];
    this.receipt!.receiptItems = receiptItems;
    this.receipt!.personId = this.receiptPerson;
    this.receipt!.store = this.store;
    this.receipt!.purchaseDate = this.datePipe.transform(this.purchaseDateFormControl.value, "dd/MM/yyyy")!;
    this.receiptService.receiptCreateUpdateReceipt({body: this.receipt!})
    .pipe(
      catchError((err: HttpErrorResponse): Observable<never> => {
        this.toastMessage = new ToastMessage("Noget gik galt, bonnen blev ikke opdateret!", ToastStatus.Error);
        return throwError(err);
      }),
      take(1)
    )
    .subscribe((): void => {
      this.toastMessage = new ToastMessage("Bon blev succesfuldt opdateret!", ToastStatus.Success);
      this.dialogRef?.close(true);
    });
  }

  dateSplitter(dateString: string): Date{
    var dateParts = dateString.split("/");
    return new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
  }
}

export class ReceiptItemClass implements ReceiptItemDto{
  constructor(
    public amount: number, 
    public name: string,
    public receiptId: string,
    public settled: boolean,
    public categoryId?: string,
    public comment?: string,
    public personId?: string){
      this.amount = amount;
      this.name = name;
      this.receiptId = receiptId;
      this.settled = settled;
      this.categoryId = categoryId;
      this.personId = personId;
      this.comment = comment;
  }
}