<div class="container">
    <div class="header-container">
        <h3>Kategorier</h3>
        <button mat-raised-button color="primary" (click)="add()">
            <mat-icon>category</mat-icon>
            Tilføj
        </button>
    </div>
    
    <table mat-table
    [dataSource]="receiptCategories" multiTemplateDataRows
    class="receipt-category-table">
    <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Reference ID</th>
    <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>
    
    <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>Slet</th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" 
        aria-label="Delete"
        (click)="delete(element)">
        <mat-icon>delete</mat-icon>
        </button>
    </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Rediger</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" 
            aria-label="Edit"
            (click)="edit(element)">
            <mat-icon>create</mat-icon>
            </button>
        </td>
        </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>
