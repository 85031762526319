<div class="container">
    <div class="header-container">
        <h3>Personer</h3>
        <button mat-raised-button color="primary" (click)="add()">
            <mat-icon>person_add</mat-icon> 
            Tilføj
        </button>
    </div>
    

    <table mat-table
      [dataSource]="persons" multiTemplateDataRows
      class="person-table">
      
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Navn</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      
      <ng-container matColumnDef="last4Digits">
        <th mat-header-cell *matHeaderCellDef>4 cifre</th>
        <td mat-cell *matCellDef="let element">{{element.last4Digits}}</td>
      </ng-container>
      
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Slet</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" 
            aria-label="Example icon button with a delete icon"
            (click)="delete(element)">
              <mat-icon>delete</mat-icon>
            </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Rediger</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" aria-label="" (click)="edit(element)">
            <mat-icon>create</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<lib-toast-message [toastMessage]="toastMessage"></lib-toast-message>