import {Component} from '@angular/core';
import { PersonDto, ReceiptDto, ReceiptItemDto, ReceiptStatus } from 'src/app/api/models';
import { ReceiptService } from 'src/app/api/services';
import { ReceiptItemClass } from '../add-receipt-form/receipt-form.component';

@Component({
    selector: 'add-receipt.component',
    templateUrl: 'add-receipt.component.html',
    styleUrls: ['add-receipt.component.scss'],
  })

export class AddReceiptComponent {
    constructor(public recieptService: ReceiptService) {}
    loading: boolean = false;
    receipt?: ReceiptDto;
    uploadFile(event: any){
      this.loading = true;
      const file: Blob = event.target.files[0];
      this.recieptService
        .receiptUploadFile({body: {file: file}})
        .subscribe((receipt: ReceiptDto) =>  
        {
          this.receipt = receipt;
          this.loading = false;
        });
    }

    createManually(){
      this.receipt = new ReceiptClass();
    }
  }
  export class ReceiptClass implements ReceiptDto {
    stringTranslation?: string | undefined;
    id?: string | undefined;
    person?: PersonDto;
    personId: string = '';
    purchaseDate?: string | undefined;
    referenceId?: string | undefined;
    settlementPeriodId?: string | null | undefined;
    public receiptItems: ReceiptItemDto[];
    public status: ReceiptStatus;
    public store: string;
    public amount: number;
    constructor(){
      this.receiptItems = new Array<ReceiptItemClass>();
      this.store = '';
      this.amount = 0;
      this.status = ReceiptStatus.Processing;
    }; 
  }
